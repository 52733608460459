import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Footer from "component/container/Footer";
import Header from "component/container/Header";

import ResultSection from "./components/calculation-result/ResultSection";
import { ResultRouteState } from "./types/form";
import { getImageUrl } from "utils/getImageUrl";

const ResultPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { formData, calculatorResult }: ResultRouteState = state ?? {};
  const [usdRate, setusdRate] = useState(0);
  const [idrRate, setidrRate] = useState(0);
  const [headerData, setHeaderData] = useState(null);
  const [footerData, setFooterData] = useState(null);
  const [resultData, setResultData] = useState(null);

  const backgroundStyle = {
    backgroundImage: `url(${getImageUrl(
      resultData?.heroSection?.background?.data?.attributes?.url
    )})`,
  };

  const fetchHeaderData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_BASE_URL}/header?populate=deep`
      );
      const data = await response.json();

      setHeaderData(data.data.attributes);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFooterData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_BASE_URL}/footer?populate=deep`
      );
      const data = await response.json();

      setFooterData(data.data.attributes);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchResultData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_BASE_URL}/result?populate=deep`
      );
      const data = await response.json();

      setResultData(data.data.attributes);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCurrencyConvertion = async () => {
    try {
      const response = await fetch(
        `https://api.exchangerate-api.com/v4/latest/IDR`
      );

      const responseDollar = await fetch(
        `https://api.exchangerate-api.com/v4/latest/USD`
      );

      const data = await response.json();

      const dataDollar = await responseDollar.json();

      setusdRate(data.rates.USD);
      setidrRate(dataDollar.rates.IDR);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!formData || !calculatorResult) {
      navigate("/energy-calculator");
    }

    fetchCurrencyConvertion();
    fetchHeaderData();
    fetchFooterData();
    fetchResultData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="result-container">
      {headerData && resultData && footerData ? (
        <>
          <Header business headerData={headerData} />
          <div className="bg-image" style={backgroundStyle} />
          {formData && calculatorResult ? (
            <ResultSection
              usdRate={usdRate}
              idrRate={idrRate}
              resultData={resultData}
            />
          ) : undefined}
          <Footer business footerData={footerData} />
        </>
      ) : null}
    </div>
  );
};

export default ResultPage;
