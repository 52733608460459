import React from 'react'
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { FormSchema } from '../../types/form';
import { FormWrapper } from './FormWrapper'
import { ImageSelect } from '../shared/ImageSelect';
import { getImageUrl } from 'utils/getImageUrl';

type Props = {
    sectionData: any;
    fuelAssets: any;
  };
  
  function FuelForm(props: Props): JSX.Element {
    const {
        formState: { errors },
        control
    } = useFormContext<FormSchema>();

    const { replace, } = useFieldArray({
        name: 'energyUsages',
        control,
    });

    return (
        <FormWrapper title={props?.sectionData?.header} description={props?.sectionData?.subHeader}>
            <div className='px-3 py-0 px-lg-7 py-lg-3'>
                <h5 className='title-s mb-3 mb-md-24'>{props?.sectionData?.title}</h5>
                <Controller
                    name="fuels"
                    defaultValue={[]}
                    rules={{
                        validate: {
                            maxLength: (value) =>
                                value.length <= 2 || "Maximum 2 options are selected",
                            minLength: (value) =>
                                Boolean(value.length) || "Minimum 1 option are selected"
                        }
                    }}
                    render={({ field: { onChange, value } }) => (
                        <ImageSelect
                            multiple
                            data={props?.fuelAssets?.map((fuel) => ({
                                value: fuel?.attributes?.value,
                                label: fuel?.attributes?.name,
                                image: getImageUrl(fuel?.attributes?.image?.data?.attributes?.url),
                            }))}
                            value={value}
                            onChange={(payload) => {
                                replace(payload.map((name) => ({
                                    name,
                                    title: undefined,
                                    unit: undefined,
                                    usageValue: undefined,
                                })))
                                onChange(payload)
                            }}
                        />
                    )}
                />
                {errors.fuels?.message && <div className="invalid-feedback d-block">
                    {errors.fuels.message}
                </div>}
            </div>
        </FormWrapper>
    )
}

export default FuelForm