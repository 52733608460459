import Button from "component/Button";
import { getImageUrl } from "utils/getImageUrl";

const Prosess = ({ sectionData }) => {
  return (
    <div className="proses gradient-bg-to ">
      <div className="title-proses" data-aos="fade-up">
        {sectionData?.title}
      </div>
      <div className="desc-proses" data-aos="fade-up">
        {sectionData?.subtitle}
      </div>
      <div className="container">
        {sectionData?.items?.map((data, idx) => {
          return (
            <div key={`data-proses-${data.title}-${idx}`}>
              <div className="row p-5">
                <div
                  className="col-12 col-md-6 content-desc-prosess"
                  data-aos="fade-right"
                >
                  <div className="number-content-proses">{idx + 1}</div>
                  <div className="title-content-proses">{data.title}</div>
                  <div className="desc-content-proses">{data.description}</div>
                  {idx === 0 && (
                    <>
                      <br />
                      <a
                        href={sectionData?.registerButton?.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="title">
                          {sectionData?.registerText}
                        </div>
                      </a>
                    </>
                  )}
                </div>
                <div className="col-12 col-md-6" data-aos="fade-left">
                  <img
                    src={getImageUrl(data?.image?.data?.attributes?.url)}
                    alt="LogoGas kita"
                  />
                </div>
              </div>
              {idx + 1 !== 4 ? <hr /> : ""}
            </div>
          );
        })}
      </div>
      <Button
        type="link"
        href={sectionData?.registerButton?.url}
        className="btn-default px-md-5 btn-mobile"
        isExternal
        target="_blank"
      >
        <div className="title">{sectionData?.registerButton?.title}</div>
      </Button>
    </div>
  );
};

export default Prosess;
