import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { NumericFormat } from "react-number-format";

import { FormSchema } from "../../types/form";
import { FormWrapper } from "./FormWrapper";

type Props = {
  sectionData: any;
  fuelAssets: any;
};

function EnergyUsageForm(props: Props): JSX.Element {
  const {
    register,
    watch,
    formState: { errors },
    control,
  } = useFormContext<FormSchema>();
  const { fields } = useFieldArray({
    name: "energyUsages",
    control,
  });

  return (
    <FormWrapper
      title={props?.sectionData?.header}
      description={props?.sectionData?.subHeader}
    >
      <div className="px-3 py-0 px-lg-7 py-lg-3">
        {fields.length &&
          fields.map((fuel, i) => {
            const findFuel = props?.fuelAssets?.find(
              (item) => item?.attributes?.value === fuel.name
            );
            if (!findFuel) {
              return <></>;
            }

            const isInvalidClass = errors.energyUsages?.[i]?.usageValue
              ? "is-invalid"
              : "";
            const watchUnit = watch(`energyUsages.${i}.unit`);

            return (
              <div key={i} className="mb-5">
                <h5 className="title-s mb-3 mb-md-24">{`How much do you use ${findFuel?.attributes?.name} monthly?`}</h5>
                <div className="w-100 w-lg-75">
                  <input
                    type="hidden"
                    {...register(`energyUsages.${i}.name`)}
                    defaultValue={findFuel.name}
                  />
                  <input
                    type="hidden"
                    {...register(`energyUsages.${i}.title`)}
                    defaultValue={findFuel.title}
                  />
                  <div className="input-group input-group-lg row no-gutters">
                    <Controller
                      name={`energyUsages.${i}.usageValue`}
                      rules={{
                        required: {
                          value: true,
                          message: "Field is required",
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <label className="has-float-label">
                          <NumericFormat
                            className={`form-control form-control-lg ${isInvalidClass}`}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix={watchUnit === "Rupiah" ? "Rp" : undefined}
                            decimalScale={0}
                            placeholder="Input your monthly usage here"
                            name={name}
                            value={value}
                            onValueChange={(v) => {
                              onChange(v.floatValue);
                            }}
                            type="tel"
                          />
                          <span className={isInvalidClass}>
                            Your monthly usage
                          </span>
                        </label>
                      )}
                    />
                    <select
                      className="custom-select col-4 col-lg-2"
                      id="inputGroupSelect01"
                      {...register(`energyUsages.${i}.unit`, {
                        required: {
                          value: true,
                          message: "Field is required",
                        },
                      })}
                    >
                      {findFuel?.attributes?.units?.map((unit) => (
                        <option key={unit?.id} value={unit?.unit}>
                          {unit?.unit}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.energyUsages?.[i]?.usageValue?.message && (
                    <div className="invalid-feedback d-block">
                      {errors.energyUsages?.[i]?.usageValue?.message}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </FormWrapper>
  );
}

export default EnergyUsageForm;
