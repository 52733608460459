import React, { useEffect, useState } from "react";
import Hero from "component/container/Hero";
import Header from "../../component/container/Header";
import Seccsion from "component/container/Seccsion";
import People from "component/container/People";
import ImageDesc from "component/container/ImageDesc";
import Prosess from "component/container/Prosess";
import Questions from "component/container/Questions";
import More from "component/container/More";
import Footer from "component/container/Footer";

const Landing = () => {
  const [headerData, setHeaderData] = useState(null);
  const [homeData, setHomeData] = useState(null);
  const [footerData, setFooterData] = useState(null);

  const fetchHeaderData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_BASE_URL}/header?populate=deep`
      );
      const data = await response.json();

      setHeaderData(data.data.attributes);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFooterData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_BASE_URL}/footer?populate=deep`
      );
      const data = await response.json();

      setFooterData(data.data.attributes);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHomeData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_BASE_URL}/homepage?populate=deep`
      );
      const data = await response.json();

      setHomeData(data.data.attributes);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchHeaderData();
    fetchHomeData();
    fetchFooterData();
  }, []);

  return (
    <div>
      {headerData && homeData && footerData ? (
        <>
          <Header headerData={headerData} />
          <Hero heroData={homeData?.heroSection} />
          <Seccsion data={homeData?.section1} id={"KelebihanGasBumi"} />
          <People peopleData={homeData?.section2} />
          <ImageDesc sectionData={homeData?.section3} />
          <Prosess sectionData={homeData?.section4} />
          <Questions faqData={homeData?.section5} />
          <More sectionData={homeData?.section6} />
          <Footer footerData={footerData} />
        </>
      ) : null}
    </div>
  );
};

export default Landing;
