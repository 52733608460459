import React, { useState } from "react";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import { getImageUrl } from "utils/getImageUrl";

const People = ({ peopleData }) => {
  const [activePage, setNotActivePage] = useState(2);
  return (
    <div className="people">
      <div className="title-people" data-aos="fade-up">
        {peopleData?.title}
      </div>
      <div className="d-flex" data-aos="fade-up">
        {activePage === 1 ? (
          <div
            className="card-people mx-3  d-none d-md-block"
            style={{ opacity: 0 }}
          >
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column align-items-start">
                <div className="name-people"></div>
                <div className="location-people "></div>
                <div className="location-people "></div>
              </div>
            </div>
            <div className="desc-people"></div>
          </div>
        ) : (
          ""
        )}
        {peopleData?.testimonies?.map((data, idx) => {
          const items = idx + 1;
          if (
            activePage === items ||
            activePage === items - 1 ||
            activePage === items + 1
          ) {
            return (
              <div
                className="card-people mx-3 d-none d-md-block"
                key={`data-people-${data?.name}-${idx}`}
                style={{ opacity: activePage === idx + 1 ? 1 : 0.5 }}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={getImageUrl(data?.image?.data?.attributes?.url)}
                    alt="Profile People"
                  />
                  <div className="d-flex flex-column align-items-start">
                    <div className="name-people">{data?.name}</div>
                    <div className="location-people ">{data?.type}</div>
                    <div className="location-people ">{data?.location}</div>
                  </div>
                </div>
                <div className="desc-people">{data?.description}</div>
              </div>
            );
          }
          return "";
        })}
        {peopleData?.testimonies?.map((data, idx) => {
          const items = idx + 1;
          if (activePage === items) {
            return (
              <div
                className="card-people mx-3 d-block d-md-none"
                key={`data-people-${data?.name}-${idx}`}
              >
                <div className="d-flex align-items-center">
                  <img src={getImageUrl(data?.image?.data?.attributes?.url)} alt="Profile People" />
                  <div className="d-flex flex-column align-items-start">
                    <div className="name-people">{data?.name}</div>
                    <div className="location-people ">{data?.type}</div>
                    <div className="location-people ">{data?.location}</div>
                  </div>
                </div>
                <div className="desc-people">{data?.description} </div>
              </div>
            );
          }
          return "";
        })}
        {activePage === peopleData?.length ? (
          <div
            className="card-people mx-3  d-none d-md-block"
            style={{ opacity: 0 }}
          >
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column align-items-start">
                <div className="name-people"></div>
                <div className="location-people "></div>
                <div className="location-people "></div>
              </div>
            </div>
            <div className="desc-people"></div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div
        className="d-flex align-items-center content-pagination"
        data-aos="fade-up"
      >
        <div
          className="paging-people-arraw mx-3"
          onClick={() => {
            if (activePage !== 1) {
              setNotActivePage(activePage - 1);
            }
          }}
        >
          <ArrowLeft2 />
        </div>
        <div className="paging-number-people">
          {activePage} / {peopleData?.testimonies?.length}
        </div>
        <div
          className="paging-people-arraw mx-3"
          onClick={() => {
            if (activePage < peopleData?.testimonies?.length) {
              setNotActivePage(activePage + 1);
            }
          }}
        >
          <ArrowRight2 />
        </div>
      </div>
    </div>
  );
};

export default People;
