import React from "react";
import { StyleRoot } from "radium";
import { ArrowRight2 } from "iconsax-react";
import { getImageUrl } from "utils/getImageUrl";

const Hero = ({ business, heroData }) => {
  const backgroundStyle = {
    backgroundImage: `url(${getImageUrl(
      heroData?.background?.data?.attributes?.url
    )})`,

    "@media (max-width: 500px)": {
      backgroundImage: `url(${getImageUrl(
        heroData?.backgroundMobile?.data?.attributes?.url
      )})`,
    },
  };
  return (
    <StyleRoot>
      <div
        id="home"
        className={`Hero align-items-md-center align-items-start pt-4 pt-md-0`}
        style={backgroundStyle}
      >
        <div className="container d-flex align-items-start align-items-md-center">
          <div className="card-hero" data-aos="fade-right">
            <div>
              {heroData?.logo?.data?.attributes?.url && (
                <div className="d-flex justify-content-start">
                  <img
                    src={getImageUrl(heroData?.logo?.data?.attributes?.url)}
                    alt="LogoGas kita"
                  />
                </div>
              )}
              <div
                className={`${
                  business ? "title-card-bisnis" : ""
                } title-card-hero`}
              >
                <div dangerouslySetInnerHTML={{ __html: heroData?.title }} />
              </div>
              <div
                className={`${
                  business ? "title-card-bisnis" : ""
                } content-card-hero`}
              >
                {heroData?.subtitle}
              </div>
              <hr />
              <div className="btn-container">
                <a
                  target="_blank"
                  href={heroData?.registerButton?.url}
                  className="btn-default ml-0"
                  rel="noreferrer"
                >
                  <div className="title">{heroData?.registerButton?.title}</div>
                </a>
                <a href={heroData?.businessButton?.url} className="btn-light">
                  <div className="title">
                    {heroData?.businessButton?.title} <ArrowRight2 />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyleRoot>
  );
};

export default Hero;
