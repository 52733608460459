import Button from "component/Button";
import AllDay from "../../../assets/img/Icon/allday.svg";
import { getImageUrl } from "utils/getImageUrl";

const Seccsion = ({ data, business, id }) => {
  return (
    <div className={`${business ? "" : "gradient-bg"} seccsion`} id={id}>
      <div className="title-seccsion" data-aos="fade-up">
        {data?.title}
      </div>
      <div className="desc-seccsion" data-aos="fade-up">
        {data?.subtitle}
      </div>

      <div className="container">
        <div className="content-card-seccsion">
          {data?.items?.map((item, idx) => {
            return (
              <div
                className="card-seccsion mt-3 mt-md-4"
                key={`data-seccsion-${item?.title}-${idx}`}
                data-aos="fade-up"
              >
                <div className="d-flex flex-column align-items-center">
                  {item?.logo ? (
                    <img
                      src={getImageUrl(item?.logo?.data?.attributes?.url)}
                      alt="AllDay"
                    />
                  ) : (
                    <img src={AllDay} alt="AllDay" />
                  )}

                  <div className="title-card-seccsion">
                    {item?.gift ? (
                      <>
                        {item?.title}
                        <span>*</span>
                      </>
                    ) : (
                      item?.title
                    )}
                  </div>
                  <div className="desc-card-seccsion">
                    {item?.description?.split(";").length > 1
                      ? item?.description?.split(";").map((item) => {
                          return (
                            <div className="d-flex align-items-center">
                              <div className="circle" />
                              {item}
                            </div>
                          );
                        })
                      : item?.description}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {!business ? (
        <>
          <div className="syarat-dan-ketentuan">{data?.description}</div>
          <Button
            type="link"
            href={data?.registerButton?.url}
            className="btn-default px-md-5 btn-mobile"
            isExternal
            target="_blank"
          >
            <div className="title">{data?.registerButton?.title}</div>
          </Button>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Seccsion;
