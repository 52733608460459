import React from "react";
import { useLocation } from "react-router-dom";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import { InfoCircle } from "iconsax-react";
import { ResultRouteState } from "pages/EnergyCalculator/types/form";
import {
  getDollarFormat,
  getNumberFormat,
} from "pages/EnergyCalculator/utils/currency";
import PotentialCard from "../shared/PotentialCard";
// import Popover from "react-bootstrap/Popover";

type Props = {
  usdRate: number;
  currencyType: string;
  resultData: any;
};

function PotentialBudgetSavings(props: Props): JSX.Element {
  const { state } = useLocation();
  const { calculatorResult }: ResultRouteState = state ?? {};

  const formattedTotalSavingPerYear = getNumberFormat({
    value: calculatorResult.totalSavingPerYear,
    style: "currency",
  });

  const formattedTotalSavingPerYearDollar = getDollarFormat({
    value: calculatorResult.totalSavingPerYear * props.usdRate,
    style: "currency",
  });

  const formattedTotalSavingPercentage = getNumberFormat({
    value: calculatorResult.totalSavingPercentage,
    style: "percent",
    decimalScale: 1,
  });

  // const popover = (
  //   <Popover id="budget-tooltip">
  //     <Popover.Body bsPrefix="tooltip-body">
  //       This figure is a rough estimation
  //     </Popover.Body>
  //   </Popover>
  // );

  return (
    <div className="mb-4 mb-md-5">
      <div className="d-flex align-items-center justify-content-center">
        <h5 className="title-s text-center mx-auto">
          {props?.resultData?.title}
        </h5>
        {/* <OverlayTrigger
          placement="bottom"
          overlay={popover}
        >
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22Z"
              stroke="#171717"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.5 16V12"
              stroke="#171717"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.5 8H12.51"
              stroke="#171717"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </OverlayTrigger> */}
      </div>
      <div className="body-s text-center">
        {props?.resultData?.subtitle}
      </div>
      <div className="body-s text-center mb-3 mb-md-4">
        (This figure is a rough estimation)
      </div>
      <PotentialCard
        leftValue={formattedTotalSavingPercentage}
        leftDescription="From current expenses"
        rightValue={
          props.currencyType === "USD"
            ? formattedTotalSavingPerYearDollar
            : formattedTotalSavingPerYear
        }
        rightDescription="Yearly"
      />
    </div>
  );
}

export default PotentialBudgetSavings;
