import { getImageUrl } from "utils/getImageUrl";

const NaturalGas = ({ sectionData }) => {
  return (
    <div className="natural-gas">
      <div className="container d-flex flex-column align-items-center">
        <div className="title-natural-gas" data-aos="fade-up">
          {sectionData?.title}
        </div>
        <div className="desc-natural-gas" data-aos="fade-up">
          {sectionData?.subtitle}
        </div>
        <div className="row">
          {sectionData?.list?.map((data, idx) => {
            return (
              <div
                className="col-12 col-md-4 px-3"
                key={`items-natural-gas-${idx}`}
              >
                <div className="card-natural-gas" data-aos="fade-up">
                  <img
                    src={getImageUrl(data?.image?.data?.attributes?.url)}
                    alt="LogoGas kita"
                  />
                  <div className="card-content-np">
                    <div className="card-title-np">{data.title}</div>
                    <div className="card-desc-np">{data.description}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NaturalGas;
