import React from "react";
import { getImageUrl } from "utils/getImageUrl";

const LogoClient = ({ sectionData }) => {
  return (
    <div className="logoClient">
      <div className="container">
        <div className="logo-text-client">{sectionData?.title}</div>
        <div className="content-icon-client">
          {sectionData?.clients?.data?.map((data, idx) => {
            return (
              <img
                className="mx-3 my-3"
                key={`name-logo-${data?.attributes?.name}-${idx}`}
                src={getImageUrl(data?.attributes?.url)}
                alt={data?.attributes?.name}
                // width={150}
                data-aos="fade-up"
                style={{ maxHeight: "60px", width: "auto" }}
              />
            );
          })}
        </div>
        <div className="logo-text-client">{sectionData?.otherText}</div>
      </div>
    </div>
  );
};

export default LogoClient;
