import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormSchema } from "../../types/form";
import { FormWrapper } from "./FormWrapper";
import { ImageSelect } from "../shared/ImageSelect";
import { getImageUrl } from "utils/getImageUrl";

type Props = {
  sectionData: any;
  businessTypes: any;
};

function BussinessTypeForm(props: Props): JSX.Element {
  const {
    formState: { errors },
  } = useFormContext<FormSchema>();

  return (
    <FormWrapper
      title={props?.sectionData?.header}
      description={props?.sectionData?.subHeader}
    >
      <div className="px-3 py-0 px-lg-7 py-lg-3">
        <h5 className="title-s mb-3 mb-md-24">Select your type of business</h5>
        <Controller
          name="businessType"
          defaultValue={[]}
          rules={{
            required: {
              value: true,
              message: "Field is required",
            },
          }}
          render={({ field: { onChange, value } }) => (
            <ImageSelect
              variant={2}
              data={props?.businessTypes?.map((item) => ({
                value: item?.attributes?.value,
                label: item?.attributes?.title,
                image: item?.attributes?.image?.data
                  ? getImageUrl(item?.attributes?.image?.data?.attributes?.url)
                  : null,
              }))}
              value={value}
              onChange={onChange}
            />
          )}
        />
        {errors.businessType?.message && (
          <div className="invalid-feedback d-block">
            {errors.businessType.message}
          </div>
        )}
      </div>
    </FormWrapper>
  );
}

export default BussinessTypeForm;
