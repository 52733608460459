import React from "react";
import { FormWrapper } from "./FormWrapper";
import Maps from "../shared/Maps";
import { FormSchema } from "pages/EnergyCalculator/types/form";
import { Controller, useFormContext } from "react-hook-form";

type Props = {
  sectionData: any;
};

function AddressForm(props: Props): JSX.Element {
  const {
    formState: { errors },
    control,
  } = useFormContext<FormSchema>();

  return (
    <FormWrapper
      title={props?.sectionData?.header}
      description={props?.sectionData?.subHeader}
    >
      <div className="px-3 py-0 px-lg-7 py-lg-3 mb-3 mb-md-24">
        <h5 className="title-s mb-0">{props?.sectionData?.title}</h5>
      </div>
      <Controller
        name="location"
        control={control}
        rules={{
          validate: {
            isLocationValid: (value) => {
              return (
                Boolean(
                  value?.lat && value.lng && value.address && value.city
                ) || "Address is required"
              );
            },
          },
        }}
        render={({ field: { onChange, value } }) => (
          <Maps
            selectedLocations={value}
            onSelectLocation={onChange}
            mapContainerStyle={{
              width: "100%",
              height: "400px",
            }}
            description="Make sure the location on the map matches the location of the gas installation"
          />
        )}
      />
      {errors.location?.message && (
        <div className="invalid-feedback d-block">
          {errors.location.message}
        </div>
      )}
    </FormWrapper>
  );
}

export default AddressForm;
