import React from "react";
import { getImageUrl } from "utils/getImageUrl";

const ImageDesc = ({ sectionData }) => {
  console.log(sectionData);
  const backgroundStyleLeft = {
    backgroundImage: `linear-gradient(to bottom right, #0069b449, #0069b4), url(${getImageUrl(
      sectionData?.leftSide?.background?.data?.attributes?.url
    )})`,
  };

  const backgroundStyleRight = {
    backgroundImage: `linear-gradient(to bottom right, #0069b449, #0069b4), url(${getImageUrl(
      sectionData?.rightSide?.background?.data?.attributes?.url
    )})`,
  };

  return (
    <div className="ImageDesc">
      <div className="row mx-0">
        <div className="col-12 col-md-6 imageFirst" style={backgroundStyleLeft}>
          <div className="content-image">
            <div className="number-content">{sectionData?.leftSide?.title}</div>
            <div className="decs-content">
              <div>{sectionData?.leftSide?.description}</div>
              <div className="update-content">*Data diperbarui per-bulan</div>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-md-6 imageSeccond"
          style={backgroundStyleRight}
        >
          <div className="content-image">
            <div className="number-content">
              {sectionData?.rightSide?.title}
            </div>
            <div className="decs-content">
              {sectionData?.rightSide?.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageDesc;
