import React from "react";
import { useLocation } from "react-router-dom";
import { ResultRouteState } from "pages/EnergyCalculator/types/form";
import { getEnergyUsageLabels } from "pages/EnergyCalculator/utils/fuel";
import { getDollarFormat, getNumberFormat } from "../../utils/currency";
import BarChart from "../shared/BarChart";

type Props = {
  usdRate: number;
  idrRate: number;
  currencyType: string;
  handleChangeCurrency: Function;
  resultData: any;
};

function EnergyCostPerYear(props: Props): JSX.Element {
  const { state } = useLocation();
  const { formData, calculatorResult }: ResultRouteState = state ?? {};

  const energyUsageLabels = getEnergyUsageLabels(formData.energyUsages, props?.currencyType, props?.usdRate);

  const curseList = props?.resultData?.currency?.split(",");

  function numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="mb-4 mb-md-5">
      <div className="result-title-section mb-3 mb-md-4">
        <div className="title-s text-center result-item">
          {props?.resultData?.title}
        </div>
        <select
          className="custom-select col-sm-4 col-3 col-lg-1"
          value={props.currencyType}
          onChange={(event) => props.handleChangeCurrency(event)}
        >
          {curseList?.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
      <h5 className="title-s text-center">
        {`${props?.resultData?.subtitle} (${
          props.currencyType === "IDR" ? "Rupiah" : "Dollar"
        })`}
      </h5>
      {props.currencyType === "USD" && (
        <div className="body-s text-center">
          *USD 1 = IDR {numberWithCommas(props.idrRate)}
        </div>
      )}
      <div className="mt-3 mb-mt-4">
        <BarChart
          labels={[
            [
              "Your current expenditure",
              ...energyUsageLabels,
              "(Including potential carbon tax)",
            ],
            [
              "PGN's Estimated Energy Cost",
              "(Based on Average Gas Cost within Existing Pipeline Areas;",
              "LNG and CNG Rates May Differ)",
            ],
          ]}
          dataSource={[
            calculatorResult.currentExpenditurePerYear,
            calculatorResult.naturalGasExpenditurePerYear,
          ]}
          dataLabelFormatter={(value) => {
            const total = getNumberFormat({
              value,
              style: "currency",
            });

            const totalDollar = getDollarFormat({
              value: value * props.usdRate,
              style: "currency",
            });

            return `${
              props.currencyType === "IDR" ? total : totalDollar
            }\n per year`;
          }}
          yAxisFormatter={(value) => {
            const total = getNumberFormat({
              value: Number(value),
              notation: "compact",
            });

            const totalDollar = getDollarFormat({
              value: Number(value) * props.usdRate,
              notation: "compact",
            });

            return props.currencyType === "IDR" ? total : `$${totalDollar}`;
          }}
        />
      </div>
    </div>
  );
}

export default EnergyCostPerYear;
