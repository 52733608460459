import React from "react";
import { getImageUrl } from "utils/getImageUrl";

// export const meetingObtains = [
//   {
//     icon: <ReceiptDisscount />,
//     title: "Optimization of savings according to the details of your gas usage",
//   },
//   {
//     icon: <CalendarSearch />,
//     title: "Planning the fastest gas delivery time to your location",
//   },
//   {
//     icon: <ClipboardText />,
//     title: "Other technical and operational arrangements",
//   },
//   {
//     icon: <TruckFast />,
//     title: "The best mode of gas delivery to your location",
//   },
//   {
//     icon: <WalletSearch />,
//     title: "Finding the most accommodating financial scheme",
//   },
// ];

type Props = {
  resultData: any;
};

function Meeting(props: Props): JSX.Element {
  return (
    <div>
      <hr />
      <h5 className="title-s text-center mt-4 mt-lg-5 mb-2">
        {props?.resultData?.title}
      </h5>
      <p className="body-s text-center mb-3 mb-md-4">
        {props?.resultData?.subtitle1} <br />
        {props?.resultData?.subtitle2}
      </p>
      <div className="row mb-3 mb-md-5">
        {props?.resultData?.items?.map((item, i) => (
          <div key={i} className="col-6 col-lg mb-3 mb-lg-0">
            <div className="meeting-card">
              <div className="icon-wrapper mb-3">
                <img
                  src={getImageUrl(item?.icon?.data?.attributes?.url)}
                  alt="icon"
                />
              </div>
              <p className="body-s darker mb-0">{item?.title}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <a
          href={props?.resultData?.meetingButton?.url}
          target="_blank"
          rel="noreferrer"
          className="pgn-button"
        >
          {props?.resultData?.meetingButton?.title}
        </a>
      </div>
    </div>
  );
}

export default Meeting;
