import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";

import AddressForm from "./components/form/AddressForm";
import BuildingConditionForm from "./components/form/BuildingConditionForm";
import BussinessTypeForm from "./components/form/BussinessTypeForm";
import Button from "./components/shared/Button";
import CompanyDetailsForm from "./components/form/CompanyDetailsForm";
import EnergyUsageForm from "./components/form/EnergyUsageForm";
import FuelForm from "./components/form/FuelForm";
import "./styles.scss";
import { FormSchema, FormStep, ResultRouteState } from "./types/form";
import { useMultistepForm } from "./utils/useMultistepForm";
import {
  calculateEnergies,
  CalculateEnergyParams,
  CalculateEnergyResultUI,
} from "./utils/fuel";
import { getImageUrl } from "utils/getImageUrl";

const INITIAL_DATA: FormSchema = {
  fuels: [],
  energyUsages: [],
  location: undefined,
  buildingCondition: undefined,
  companyName: undefined,
  name: undefined,
  phone: undefined,
  email: undefined,
  businessType: undefined,
};

type SendLeadPayload = {
  companyName?: string;
  email?: string;
  phoneNumber?: string;
  selectedFuels?: string;
  city?: string;
};

const EnergyCalculatorPage = () => {
  const [isCalculating, setIsCalculating] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [calculatorAssets, setCalculatorAssets] = useState<any>(null);
  const [fuelAssets, setFuelAssets] = useState<any>(null);
  const [buildingConditions, setBuildingConditions] = useState<any>(null);
  const [businessTypes, setBusinessTypes] = useState<any>(null);

  const FORM_STEPS: FormStep[] = [
    {
      renderer: (
        <FuelForm
          sectionData={calculatorAssets?.step1}
          fuelAssets={fuelAssets}
        />
      ),
      image: getImageUrl(calculatorAssets?.step1?.image?.data?.attributes?.url),
    },
    {
      renderer: (
        <EnergyUsageForm
          sectionData={calculatorAssets?.step2}
          fuelAssets={fuelAssets}
        />
      ),
      image: getImageUrl(calculatorAssets?.step2?.image?.data?.attributes?.url),
    },
    {
      renderer: <AddressForm sectionData={calculatorAssets?.step3} />,
      image: getImageUrl(calculatorAssets?.step3?.image?.data?.attributes?.url),
    },
    {
      renderer: (
        <BuildingConditionForm
          sectionData={calculatorAssets?.step4}
          buildingConditions={buildingConditions}
        />
      ),
      image: getImageUrl(calculatorAssets?.step4?.image?.data?.attributes?.url),
    },
    {
      renderer: <CompanyDetailsForm sectionData={calculatorAssets?.step5} />,
      image: getImageUrl(calculatorAssets?.step5?.image?.data?.attributes?.url),
    },
    {
      renderer: (
        <BussinessTypeForm
          sectionData={calculatorAssets?.step6}
          businessTypes={businessTypes}
        />
      ),
      image: getImageUrl(calculatorAssets?.step6?.image?.data?.attributes?.url),
    },
  ];

  const fetchCalculatorAsset = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_BASE_URL}/energy-calculator?populate=deep`
      );
      const data = await response.json();

      setCalculatorAssets(data.data.attributes);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFuelsAsset = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_BASE_URL}/fuels?populate=deep`
      );
      const data = await response.json();

      setFuelAssets(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBuilingConditions = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_BASE_URL}/conditions?populate=deep`
      );
      const data = await response.json();

      setBuildingConditions(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBusinessTypes = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_BASE_URL}/business-types?populate=deep`
      );
      const data = await response.json();

      setBusinessTypes(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const { percentage, currentStep, isFirstStep, isLastStep, back, next } =
    useMultistepForm(FORM_STEPS);

  const formMethods = useForm<FormSchema>({
    mode: "onChange",
    defaultValues: INITIAL_DATA,
  });

  const navigate = useNavigate();

  const onBack = () => {
    if (isFirstStep) {
      return navigate("/business");
    }

    return back();
  };

  function onSubmit(data: FormSchema) {
    if (!isLastStep) {
      return;
    }

    setIsCalculating(true);

    const calculatorParams: CalculateEnergyParams[] = [];

    data.energyUsages.forEach((item) => {
      if (item.name && item.unit && item.usageValue) {
        calculatorParams.push({
          name: item.name,
          unit: item.unit,
          usageValue: item.usageValue,
          fuelAssets: fuelAssets,
        });
      }
    });

    const calculatorResult: CalculateEnergyResultUI = calculateEnergies(
      calculatorParams,
      fuelAssets
    );

    fetch(`${process.env.REACT_APP_API_URL}/lead`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(constructPayload(data, calculatorResult)),
    })
      .then(() => {
        const resultRouteState: ResultRouteState = {
          formData: data,
          calculatorResult,
        };

        const dataToPost = {
          company_name: resultRouteState?.formData?.companyName,
          company_email: resultRouteState?.formData?.email,
          company_phone: resultRouteState?.formData?.phone,
          city: resultRouteState?.formData?.location?.city,
          fuel_per_month_name_1:
            resultRouteState?.formData?.energyUsages[0]?.name,
          fuel_per_month_name_2:
            resultRouteState?.formData?.energyUsages[1]?.name || "",
          fuel_per_month_value_1:
            resultRouteState?.formData?.energyUsages[0]?.unit === "Rupiah"
              ? `Rp${resultRouteState?.formData?.energyUsages[0]?.usageValue}`
              : `${resultRouteState?.formData?.energyUsages[0]?.usageValue} ${resultRouteState?.formData?.energyUsages[0]?.unit}`,
          fuel_per_month_value_2:
            resultRouteState?.formData?.energyUsages[1]?.unit === "Rupiah"
              ? `Rp${resultRouteState?.formData?.energyUsages[1]?.usageValue}`
              : `${resultRouteState?.formData?.energyUsages[1]?.usageValue} ${resultRouteState?.formData?.energyUsages[1]?.unit}` ||
                "",
          equiv_vol_natural_gas:
            resultRouteState?.calculatorResult?.totalSavingPerYear,
        };

        fetch(
          `${process.env.REACT_APP_DEV_PORTAL_URL}/lead-customer/energy-calculation`,
          {
            method: "POST",
            body: JSON.stringify(dataToPost),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        );

        navigate("/energy-calculator/result", { state: resultRouteState });
      })
      .catch((error) => {
        console.log("Failed to send lead data");
        console.log(error);
        toast(
          "Please try again, something went wrong while directing you to the next page"
        );
      })
      .finally(() => {
        setIsCalculating(false);
      });
  }

  const constructPayload = (
    data: FormSchema,
    calculatorResult: CalculateEnergyResultUI
  ): SendLeadPayload => {
    const selectedFuels = calculatorResult.selectedFuels
      .map(
        (fuel) => `
      Name: ${fuel.name}
      Unit: ${fuel.unit}
      Amount: ${fuel.amount}
      naturalGasVolume: ${fuel.naturalGasVolume}`
      )
      .join("\n");

    return {
      companyName: data.companyName,
      email: data.email,
      phoneNumber: data.phone,
      city: data.location?.city,
      selectedFuels,
    };
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setIsReady(false);

        await fetchCalculatorAsset();
        await fetchFuelsAsset();
        await fetchBuilingConditions();
        await fetchBusinessTypes();

        setIsReady(true);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAllData();
  }, []);

  return (
    <>
      {isReady ? (
        <FormProvider {...formMethods}>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="calculator-container">
              <div className="d-flex w-100 h-100">
                <div className="d-none d-lg-block section-left">
                  <img src={currentStep.image} alt="Thumbnail" />
                </div>
                <div className="section-right relative">
                  <div className="content">{currentStep.renderer}</div>
                  <div className="navigation">
                    <div className="progress-bar">
                      <div
                        style={{ width: `${percentage}%` }}
                        className="bar"
                      ></div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between px-3 py-3 px-lg-7 py-lg-4">
                      <Button type="secondary" onClick={onBack}>
                        {isFirstStep ? "Back" : "Before"}
                      </Button>
                      {isLastStep ? (
                        <Button
                          disabled={
                            !formMethods.formState.isValid || isCalculating
                          }
                          onClick={formMethods.handleSubmit(onSubmit)}
                        >
                          {isCalculating ? "Calculating..." : "Calculate"}
                        </Button>
                      ) : (
                        <Button
                          disabled={
                            !formMethods.formState.isValid || isCalculating
                          }
                          onClick={next}
                        >
                          Next
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <Toaster
            position="bottom-right"
            toastOptions={{
              className: "",
              style: {
                borderRadius: "8px",
                border: "1px solid #E64A40",
                background: "#FFEEED",
                color: "#171717",
                fontWeight: 500,
                maxWidth: "max-content",
              },
            }}
          />
        </FormProvider>
      ) : null}
    </>
  );
};

export default EnergyCalculatorPage;
