import { getImageUrl } from 'utils/getImageUrl';
import Rectangle from '../../../assets/img/Rectangle.png'
import Subtract from '../../../assets/img/Subtract.png'
import { ArrowRight2 } from 'iconsax-react'

const More = ({sectionData}) => {
  const backgroundStyle = {
    backgroundImage: `url(${getImageUrl(
      sectionData?.image?.data?.attributes?.url
    )})`,
  };
  
  return (
    <div className='more-efficient'>
      <div className='row mx-0'>
        <div className='col-12 col-md-6 img-more' style={backgroundStyle} />
        <div className='col-12 col-md-6 desc-more-content'>
          <img src={Rectangle} className='img-rectangle' alt="Rectangle" />
          <img src={Subtract} className='img-subtract' alt="Subtract" />
          <div className='content-more'>
            <div className='more-title'>{sectionData?.title}</div>
            <div className='more-desc'>{sectionData?.subtitle}</div>
          </div>
          <a href={sectionData?.calculateButton?.url} className='btn-light bg-white btn-mobile' >
            <div className='title'>{sectionData?.calculateButton?.title}  <ArrowRight2 /></div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default More;