import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { App } from 'App'
const rootNode = document.getElementById('root')


if (rootNode) {
  ReactDOM.createRoot(rootNode).render(
    <StrictMode>
      <App />
    </StrictMode>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
