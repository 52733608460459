import React from "react";
import { HashLink } from "react-router-hash-link";
import Button from "component/Button";
import { getImageUrl } from "utils/getImageUrl";

const Footer = ({ business = false, footerData }) => {
  return (
    <footer>
      <div className="footer-content">
        <div className="container">
          <div className="d-flex d-md-none justify-content-between align-items-center">
            <a
              href={business ? "/business" : "/"}
              className="d-none d-md-block"
            >
              <img
                src={getImageUrl(footerData?.secondLogo?.data?.attributes?.url)}
                alt="LogoPertamina"
                className="img-widht"
              />
              <img
                src={getImageUrl(footerData?.secondLogo?.data?.attributes?.url)}
                alt="LogoCall"
              />
            </a>
          </div>
          <div className="d-flex align-items-start mt-4 mt-md-0">
            <a
              href={business ? "/business" : "/"}
              className="d-none d-md-block"
            >
              <img
                src={getImageUrl(footerData?.mainLogo?.data?.attributes?.url)}
                alt="LogoPertamina"
                className="d-none d-md-block img-widht"
              />
            </a>
            <div className="container custom-container-footer mb-5">
              <div className="row mx-0 mx-md-2 text-left">
                <div className="col-6 col-md-3 d-flex flex-column">
                  <div className="title-footer">SITEMAP</div>
                  {business ? (
                    footerData?.sitemapBusiness.map((data, idx) => {
                      return (
                        <Button
                          type="link"
                          href={data?.url}
                          className="content-footer"
                          key={`nav-default-${idx}`}
                          isExternal
                          target={data.newtab ? "_blank" : ""}
                          rel={data.newtab ? "noreferrer noopener" : ""}
                        >
                          {data.text}
                        </Button>
                      );
                    })
                  ) : (
                    <>
                      {footerData?.sitemap?.map((data, idx) => {
                        return (
                          <Button
                            type="link"
                            href={data.url}
                            className="content-footer"
                            key={`footer-default-${idx}`}
                            isExternal
                            target={data.newtab ? "_blank" : ""}
                            rel={data.newtab ? "noreferrer noopener" : ""}
                          >
                            {data.text}
                          </Button>
                        );
                      })}
                      {/* <Button
                        type="link"
                        href="https://online.pgn.co.id/register/residensial"
                        className="content-footer"
                        isExternal
                        target="_blank"
                      >
                        Registrasi
                      </Button> */}
                    </>
                  )}
                </div>
                <div className="col-6 col-md-4 mt-md-0 d-flex flex-column">
                  <div className="title-footer">CONTACT</div>
                  {footerData?.contact?.map((item) => (
                    <a
                      target="_blank"
                      href={item?.url}
                      className="content-footer"
                      rel="noreferrer"
                      key={item?.text}
                    >
                      <img
                        className="item-icon"
                        src={getImageUrl(item?.icon?.data?.attributes?.url)}
                        alt="icon"
                      />
                      {item?.text}
                    </a>
                  ))}
                </div>
                <div className="col-6 col-md-4 d-flex flex-column">
                  {/* <div className='title-footer'> TERMS OF CONDITIONS</div>
                  <Button type='link' href="#" className='content-footer'>Kebijakan Privasi</Button>
                  <Button type='link' href="#" className='content-footer'>Syarat Ketentuan</Button> */}
                </div>
              </div>
            </div>
            <img
              src={getImageUrl(footerData?.secondLogo?.data?.attributes?.url)}
              alt="LogoCall"
              className="d-none d-md-block"
            />
          </div>
          <hr />
          <div className="footer-icon-item">
            <div className="footer-copyright">
              {footerData?.bottomText}
            </div>
            <div className="d-flex icon-social-container">
              {footerData?.socialMedias?.map((item) => (
                <a
                  href={item?.url}
                  target="_blank"
                  className="icon-social-footer mx-2"
                  rel="noreferrer"
                  key={item?.url}
                >
                  <img
                    src={getImageUrl(item?.icon?.data?.attributes?.url)}
                    alt="Logo Social Media"
                  />
                </a>
              ))}
              {/* <a
                href="https://www.instagram.com/gas_negara/?hl=en"
                target="_blank"
                className="icon-social-footer mr-2"
                rel="noreferrer"
              >
                <img src={Instagram} alt="Logo Instagram" />
              </a>
              <a
                href="https://twitter.com/gas_negara?lang=en"
                target="_blank"
                className="icon-social-footer mx-2"
                rel="noreferrer"
              >
                <img src={Twitter} alt="Logo Twitter" />
              </a>
              <a
                href="https://www.youtube.com/channel/UC9KAsjWek27hCNttej5KWMw"
                target="_blank"
                className="icon-social-footer mx-2"
                rel="noreferrer"
              >
                <Youtube variant="Bold" />
              </a>
              <a
                href="https://id-id.facebook.com/pgn.co.id/"
                target="_blank"
                className="icon-social-footer ml-2"
                rel="noreferrer"
              >
                <Facebook variant="Bold" />
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
