import React, { useEffect, useState } from "react";
import Hero from "component/container/Hero";
import Header from "../../component/container/Header";
import Seccsion from "component/container/Seccsion";
import LogoClient from "component/container/LogoClient";
import Footer from "component/container/Footer";
import CalculatorContent from "component/container/CalculatorContent";
import NaturalGas from "component/container/NaturalGas";
import Provide from "component/container/Provide";

export default function LandingBusiness() {
  const [headerData, setHeaderData] = useState(null);
  const [businessData, setBusinessData] = useState(null);
  const [footerData, setFooterData] = useState(null);

  const fetchHeaderData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_BASE_URL}/header?populate=deep`
      );
      const data = await response.json();

      setHeaderData(data.data.attributes);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFooterData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_BASE_URL}/footer?populate=deep`
      );
      const data = await response.json();

      setFooterData(data.data.attributes);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHomeData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_BASE_URL}/business?populate=deep`
      );
      const data = await response.json();

      setBusinessData(data.data.attributes);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchHeaderData();
    fetchHomeData();
    fetchFooterData();
  }, []);

  return (
    <div>
      {headerData && businessData && footerData ? (
        <>
          <Header business headerData={headerData} />
          <Hero business heroData={businessData?.heroSection} />
          <LogoClient sectionData={businessData?.section1} />
          <CalculatorContent sectionData={businessData?.section2} id="CalculateSavings" />
          <Seccsion business id="NaturalGasBenefits" data={businessData?.section3} />
          <NaturalGas sectionData={businessData?.section4} />
          <Provide sectionData={businessData?.section5} />
          <Footer business footerData={footerData} />
        </>
      ) : null}
    </div>
  );
}
