import React from "react";
import { useFormContext } from "react-hook-form";
import { FormSchema } from "../../types/form";
import { FormWrapper } from "./FormWrapper";

type Props = {
  sectionData: any;
};

function CompanyDetailsForm(props: Props): JSX.Element {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormSchema>();
  const invalidErrorClass = "is-invalid";

  const allowOnlyNumber = (event) => {
    event.target.value = event.target.value.replace(/[^0-9]/g, "");
  };

  return (
    <FormWrapper
      title={props?.sectionData?.header}
      description={props?.sectionData?.subHeader}
    >
      <div className="px-3 py-0 px-lg-7 py-lg-3">
        <h5 className="title-s mb-3 mb-md-24">{props?.sectionData?.title}</h5>
        <div className="mb-3 mb-md-24">
          <label htmlFor="company-name" className="has-float-label">
            <input
              id="company-name"
              type="text"
              className={[
                "form-control form-control-lg",
                errors.companyName?.message && invalidErrorClass,
              ].join(" ")}
              placeholder="Company Name"
              {...register("companyName", {
                required: {
                  value: true,
                  message: "Field is required",
                },
              })}
            />
            <span className={errors.companyName?.message && invalidErrorClass}>
              Company Name
            </span>
          </label>
          {errors.companyName?.message && (
            <div className="invalid-feedback d-block ml-3">
              {errors.companyName.message}
            </div>
          )}
        </div>
        <div className="mb-3 mb-md-24">
          <label htmlFor="name" className="has-float-label">
            <input
              id="name"
              type="text"
              className={[
                "form-control form-control-lg",
                errors.name?.message && invalidErrorClass,
              ].join(" ")}
              placeholder="Name"
              {...register("name", {
                required: {
                  value: true,
                  message: "Field is required",
                },
              })}
            />
            <span className={errors.name?.message && invalidErrorClass}>
              Name
            </span>
          </label>
          {errors.name?.message && (
            <div className="invalid-feedback d-block ml-3">
              {errors.name.message}
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="col-lg-6 mb-3 mb-md-24">
            <label htmlFor="phone" className="has-float-label">
              <input
                id="phone"
                type="tel"
                pattern="[+]{1}[0-9]{11,14}"
                className={[
                  "form-control form-control-lg",
                  errors.phone?.message && invalidErrorClass,
                ].join(" ")}
                placeholder="Phone"
                onInput={(event) => allowOnlyNumber(event)}
                {...register("phone", {
                  required: {
                    value: true,
                    message: "Field is required",
                  },
                  pattern: {
                    value:
                      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,8}$/,
                    message: "Please input a valid phone number",
                  },
                })}
              />
              <span className={errors.phone?.message && invalidErrorClass}>
                Phone
              </span>
            </label>
            {errors.phone?.message && (
              <div className="invalid-feedback d-block ml-3">
                {errors.phone.message}
              </div>
            )}
          </div>
          <div className="col-lg-6 mb-3 mb-md-24">
            <label htmlFor="email" className="has-float-label">
              <input
                id="email"
                type="email"
                className={[
                  "form-control form-control-lg",
                  errors.email?.message && invalidErrorClass,
                ].join(" ")}
                placeholder="Email"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Field is required",
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email format",
                  },
                })}
              />
              <span className={errors.email?.message && invalidErrorClass}>
                Email
              </span>
            </label>
            {errors.email?.message && (
              <div className="invalid-feedback d-block ml-3">
                {errors.email.message}
              </div>
            )}
            {/* <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            className={["form-control form-control-lg", errors.email?.message && invalidErrorClass].join(' ')}
                            id="email"
                            {...register("email", {
                                required: {
                                    value: true,
                                    message: "Field is required"
                                },
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email format"
                                }
                            })}
                        />
                        {errors.email?.message && <div className="invalid-feedback d-block">
                            {errors.email.message}
                        </div>} */}
          </div>
        </div>
      </div>
    </FormWrapper>
  );
}

export default CompanyDetailsForm;
