import React, { useState } from "react";
import EnergyCostPerYear from "./EnergyCostPerYear";
import Meeting from "./Meeting";
import PotentialBudgetSavings from "./PotentialBudgetSavings";
import PotentialCarbonEmissionReduction from "./PotentialCarbonEmissionReduction";

type Props = {
  usdRate: number;
  idrRate: number;
  resultData: any;
};


function ResultSection(props: Props): JSX.Element {
  const [currencyType, setCurrencyType] = useState("IDR");

  const handleChangeCurrency = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    setCurrencyType(value);
  };
  return (
    <div className="container result-section">
      <div className="mb-3 mb-md-4">
        <h1 className="title-l text-white">
        {props?.resultData?.heroSection?.title}
        </h1>
        <p className="body-m text-white">
          {props?.resultData?.heroSection?.subtitle}
        </p>
      </div>
      <div className="result-card px-3 py-4 p-lg-5">
        <EnergyCostPerYear
          usdRate={props.usdRate}
          idrRate={props.idrRate}
          currencyType={currencyType}
          handleChangeCurrency={handleChangeCurrency}
          resultData={props?.resultData?.section1}
        />
        <PotentialBudgetSavings
          usdRate={props.usdRate}
          currencyType={currencyType}
          resultData={props?.resultData?.section2}
        />
        <PotentialCarbonEmissionReduction resultData={props?.resultData?.sectionEmission} />
        <Meeting resultData={props?.resultData?.section4} />
      </div>
    </div>
  );
}

export default ResultSection;
