/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { HambergerMenu, Whatsapp } from "iconsax-react";
import Modal from "react-modal";
import { HashLink } from "react-router-hash-link";
import { BOOK_MEETING_URL } from "constants/meeting";
import { getImageUrl } from "utils/getImageUrl";

const customStyles = {
  overlay: {
    zIndex: 999,
  },
  content: {
    height: window.innerHeight,
    width: window.innerWidth,
    background: "#fff",
  },
};

const Header = ({ business, headerData }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <a
            className="navbar-brand d-flex"
            href={business ? "/business" : "/"}
          >
            <div style={{ width: 141, height: 42 }}>
              <img
                src={getImageUrl(headerData?.logo?.data?.attributes?.url)}
                alt="LogoPertamina"
                className="img-header"
              />
            </div>
          </a>
          <div
            className="d-flex d-md-none"
            onClick={() => setOpenModal(!openModal)}
          >
            <HambergerMenu />
          </div>
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item" />
            </ul>
            <div>
              {business ? (
                <ul className="navbar-nav nav-header-custom mr-auto d-flex align-items-center">
                  {headerData?.menuBusiness?.map((data, idx) => {
                    return (
                      <li
                        className="nav-item active mx-2"
                        key={`nav-default-${idx}`}
                      >
                        <HashLink to={data?.url} className="nav-link">
                          {data.title}
                        </HashLink>
                      </li>
                    );
                  })}
                  {headerData?.redirectButton?.url &&
                  headerData?.redirectButton?.title ? (
                    <li>
                      <a
                        target="_blank"
                        href={headerData?.redirectButton?.url}
                        className="btn-light"
                        rel="noreferrer"
                      >
                        <div className="title">
                          {headerData?.redirectButton?.title}
                        </div>
                      </a>
                    </li>
                  ) : null}
                </ul>
              ) : (
                <ul className="navbar-nav nav-header-custom mr-auto d-flex align-items-center">
                  {headerData?.menus?.map((data, idx) => {
                    return (
                      <li
                        className="nav-item active mx-2"
                        key={`nav-default-${idx}`}
                      >
                        <HashLink to={data?.url} className="nav-link">
                          {data.title}
                        </HashLink>
                      </li>
                    );
                  })}
                  <li>
                    <a
                      target="_blank"
                      href={headerData?.contactUs?.url}
                      className="btn-whatsApp"
                      rel="noreferrer"
                    >
                      <img
                        src={getImageUrl(
                          headerData?.contactUs?.icon?.data?.attributes?.url
                        )}
                        alt="WhatsAppIcon"
                        style={{ marginRight: 8 }}
                      />
                      <div className="title">
                        {headerData?.contactUs?.title}
                      </div>
                    </a>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <Modal
          isOpen={openModal}
          style={customStyles}
          className="modalNavCustomHero"
          contentLabel="Example Modal"
        >
          <div>
            <div className="modalNavHeaderHero">
              <img
                src={getImageUrl(headerData?.logo?.data?.attributes?.url)}
                alt="Logo Header Pertamina"
              />
              <span onClick={() => setOpenModal(!openModal)}>X</span>
            </div>
            <div
              className="d-flex flex-column justify-content-between"
              style={{ height: window.innerHeight - 100 }}
            >
              <div className="items-modal">
                {business
                  ? headerData?.menuBusiness?.map((data, idx) => {
                      return (
                        <a
                          href={data?.url}
                          onClick={() => setOpenModal(!openModal)}
                          key={`nav-default-${idx}-mobile`}
                        >
                          {data.title}
                        </a>
                      );
                    })
                  : headerData?.menus?.map((data, idx) => {
                      return (
                        <a
                          href={data?.url}
                          onClick={() => setOpenModal(!openModal)}
                          key={`nav-default-${idx}-mobile`}
                        >
                          {data.title}
                        </a>
                      );
                    })}
              </div>
              {business ? (
                <div>
                  <a
                    target="_blank"
                    href={headerData?.redirectButton?.url}
                    className="btn-light btn-mobile"
                    rel="noreferrer"
                  >
                    <div className="title">
                      {headerData?.redirectButton?.title}
                    </div>
                  </a>

                  <a
                    target="_blank"
                    href={BOOK_MEETING_URL}
                    className="btn-default mt-3"
                    rel="noreferrer"
                  >
                    <div className="title">Book an Online Meeting</div>
                  </a>
                </div>
              ) : (
                <div>
                  <a
                    target="_blank"
                    href="https://api.whatsapp.com/send/?phone=6281511500645&text&type=phone_number&app_absent=0"
                    className="btn-whatsApp"
                    rel="noreferrer"
                  >
                    <Whatsapp />{" "}
                    <div className="title">{headerData?.contactUs.title}</div>
                  </a>
                  <a
                    target="_blank"
                    href="https://online.pgn.co.id/register/residensial"
                    className="btn-default mt-3"
                    rel="noreferrer"
                  >
                    <div className="title">Daftar Sekarang</div>
                  </a>
                </div>
              )}
            </div>
          </div>
        </Modal>
      </nav>
      <div className="line-header">
        <div className="row">
          <div className="col-8">
            <hr />
          </div>
          <div className="col-4">
            <hr />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
