const Provide = ({ id, sectionData }) => {
  return (
    <div className='provide' id={id}>
      <div className='title-provide' data-aos="fade-up">
        {sectionData?.title}
      </div>
      <div className='desc-provide' data-aos="fade-up">
        {sectionData?.subtitle}
      </div>

      <div className='card-provide-content'>
        {sectionData?.items?.map((data, idx) => {
          return (
            <div className='px-3 card-margin-provide' key={`data-provide-${data.title}-${idx}`} data-aos="fade-up">
              <div className='d-flex flex-column'>
                <div className='card-number-provide'>{idx + 1}</div>
                <div className='field-card-provide'>
                  <div className='title-card-provide'>
                    {data.title}
                  </div>
                  <div className='desc-card-provide'>
                    {data.description}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className='text-content-provide '>{sectionData?.otherText}</div>
      <a target="_blank" href={sectionData?.meetingButton?.url} className='btn-default px-md-5 btn-mobile' rel="noreferrer" >
        <div className='title'>{sectionData?.meetingButton?.title}</div>
      </a>
    </div>
  )
}

export default Provide;