import React from "react";
import Button from "../../../component/Button";
import { ArrowRight2 } from "iconsax-react";
import { getImageUrl } from "utils/getImageUrl";

const CalculatorContent = ({ id, sectionData }) => {
  return (
    <div className="calculatorContent gradient-bg-to " id={id}>
      <div className="container">
        <div className="row">
          <div
            className="col-12 col-md-6"
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <img
              src={getImageUrl(sectionData?.image?.data?.attributes?.url)}
              alt="Calculator"
            />
          </div>
          <div
            className="col-12 col-md-6 calculatorContentItem px-5 py-3"
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <div className="calculator-efficency">{sectionData?.labelText}</div>
            <div className="calculator-title">
              <div dangerouslySetInnerHTML={{ __html: sectionData?.title }} />
            </div>
            <div className="calculator-desc">{sectionData?.subtitle}</div>
            <Button
              type="link"
              href={sectionData?.calculateButton?.url}
              className="btn-default btn-mobile mx-0"
            >
              <div className="title">
                {sectionData?.calculateButton?.title} <ArrowRight2 />
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculatorContent;
